import React, { Component } from 'react';

import FocusNav from 'common/FocusNav';

import 'css/components/_RegistrationWrapper.scss';

export default class ContentWrapper extends Component {
  render() {
    return (
      <div className="pageWrapper">
        <FocusNav />
        <div className="registrationWrapper">{this.props.children}</div>
      </div>
    );
  }
}
