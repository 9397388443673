import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import Link from './Link';
import NavLogo from './NavLogo';
import UppercaseHeader from './UppercaseHeader';

import 'css/components/_FocusNav.scss';

class FocusNav extends Component {
  static propTypes = {
    location: PropTypes.object,
    router: PropTypes.object,
    viewer: PropTypes.shape({
      companies: PropTypes.array,
    }),
  };

  onLoginTapped = () => {
    const { router, viewer } = this.props;
    const { companies } = viewer;
    if (viewer && viewer.companies && viewer.companies.length === 0) {
      // logged in && no companies -> create a company
      router.push('/register');
      return;
    } else if (!viewer || viewer.loggedOut) {
      // logged out -> sign in
      router.push('/login');
      return;
    } else {
      // logged in && has company -> admin dashboard
      // assume first company without redirection url for now
      const company = companies.find((c) => !c.integrationAuthRedirectionURL) ?? companies[0];
      const companyURL =
        company.integrationAuthRedirectionURL ??
        devURL(`https://${company.subdomain}.canny.io/admin`);
      window.location.assign(companyURL);
    }
  };

  renderLink() {
    const { viewer } = this.props;
    if (this.props.location.pathname === '/login') {
      return (
        <Link className="link" to="/register">
          Sign up
        </Link>
      );
    } else {
      if (viewer) {
        return null;
      }
      return (
        <Link className="link" fakeLink={true} onTap={this.onLoginTapped} to="/login">
          Log&nbsp;in
        </Link>
      );
    }
  }

  render() {
    return (
      <div className="focusNav">
        <div className="focusNavContents">
          <div className="leftContainer">
            <NavLogo />
          </div>
          <UppercaseHeader className="right">{this.renderLink()}</UppercaseHeader>
        </div>
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  router: RouterContext,
  viewer: ViewerContext,
})(FocusNav);
