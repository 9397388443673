import React, { Component } from 'react';

import Nav from 'common/Nav';

import 'css/components/_ContentWrapper.scss';

export default class ContentWrapper extends Component {
  render() {
    return (
      <div className="pageWrapper">
        <div>
          <Nav />
          <div className="contentWrapper">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
