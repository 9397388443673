import React, { useContext } from 'react';

import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import devURL from 'common/util/devURL';

import ContentContainer from './containers/ContentContainer';
import Link from './Link';
import NavLogo from './NavLogo';
import UppercaseHeader from './UppercaseHeader';

import 'css/components/_Nav.scss';

const Nav = () => {
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);
  const viewer = useContext(ViewerContext);

  const onLoginTapped = () => {
    const { companies } = viewer;
    if (viewer && companies && companies.length !== 0) {
      // logged in && has company -> admin dashboard
      // Find first company without redirection url, if none, use the first company
      const company = companies?.find((c) => !c.integrationAuthRedirectionURL) ?? companies[0];
      const companyURL =
        company?.integrationAuthRedirectionURL ??
        devURL(`https://${company.subdomain}.canny.io/admin`);
      window.location.assign(companyURL);
    } else if (!viewer || viewer.loggedOut) {
      // logged out -> sign in
      router.push('/login');
    } else {
      // logged in && no companies -> create a company
      router.push('/register');
    }
  };

  const showAuthLink = location.pathname !== '/accept-invite';

  return (
    <ContentContainer outerClassName="nav" innerClassName="navContents">
      <div className="leftContainer">
        <NavLogo />
      </div>
      {showAuthLink ? (
        <UppercaseHeader className="right">
          {location.pathname === '/login' ? (
            <Link className="link" to="/register">
              Sign up
            </Link>
          ) : (
            <Link className="link" fakeLink={true} onTap={onLoginTapped} to="/login">
              Log in
            </Link>
          )}
        </UppercaseHeader>
      ) : null}
    </ContentContainer>
  );
};

export default Nav;
